// Generated by Framer (0b5b7f5)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Embed from "https://framerusercontent.com/modules/o1PI5S8YtkA5bP5g4dFz/Mn69eMnaMcqJ65TjxwR1/Embed.js";
const EmbedFonts = getFonts(Embed);

const cycleOrder = ["qbIp4Rl2g", "ID1Tv58HS"];

const serializationHash = "framer-h3Myp"

const variantClassNames = {ID1Tv58HS: "framer-v-1ae1lb6", qbIp4Rl2g: "framer-v-1lkay0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "qbIp4Rl2g", "Variant 2": "ID1Tv58HS"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "qbIp4Rl2g"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "qbIp4Rl2g", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1lkay0", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"qbIp4Rl2g"} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({ID1Tv58HS: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 739, intrinsicWidth: 1440, pixelHeight: 739, pixelWidth: 1440, sizes: `calc(${componentViewport?.width || "100vw"} + 134px)`, src: "https://framerusercontent.com/images/QHB9QmglyhoxDzZqFGKR0vgqZA.png", srcSet: "https://framerusercontent.com/images/QHB9QmglyhoxDzZqFGKR0vgqZA.png?scale-down-to=512 512w,https://framerusercontent.com/images/QHB9QmglyhoxDzZqFGKR0vgqZA.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/QHB9QmglyhoxDzZqFGKR0vgqZA.png 1440w"}} className={"framer-qo4oc0"} data-framer-name={"Pricing"} layoutDependency={layoutDependency} layoutId={"FNyFJJMNf"}/><ComponentViewportProvider ><motion.div className={"framer-wi1nlb-container"} layoutDependency={layoutDependency} layoutId={"HMqzq8iLJ-container"}><Embed height={"100%"} html={"<video src=\"https://ena-supply.b-cdn.net/Apex%20Films/Crisp%20thumbnail.mp4\" autoplay=\"false\" loop=\"true\" muted=\"false\" preload=\"none\" style=\"border:0;position:absolute;top:0;height:100%;width:100%;\"></video>\n"} id={"HMqzq8iLJ"} layoutId={"HMqzq8iLJ"} style={{height: "100%", width: "100%"}} type={"html"} url={""} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-h3Myp.framer-1h975rq, .framer-h3Myp .framer-1h975rq { display: block; }", ".framer-h3Myp.framer-1lkay0 { height: 1125px; overflow: hidden; position: relative; width: 2080px; }", ".framer-h3Myp .framer-qo4oc0 { aspect-ratio: 1.9485791610284169 / 1; bottom: -9px; flex: none; height: var(--framer-aspect-ratio-supported, 1136px); left: -67px; overflow: visible; position: absolute; right: -67px; }", ".framer-h3Myp .framer-wi1nlb-container { flex: none; height: 555px; left: calc(50.00000000000002% - 979px / 2); position: absolute; top: calc(49.95555555555558% - 555px / 2); width: 979px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1125
 * @framerIntrinsicWidth 2080
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ID1Tv58HS":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerWwXrTZBL6: React.ComponentType<Props> = withCSS(Component, css, "framer-h3Myp") as typeof Component;
export default FramerWwXrTZBL6;

FramerWwXrTZBL6.displayName = "Video";

FramerWwXrTZBL6.defaultProps = {height: 1125, width: 2080};

addPropertyControls(FramerWwXrTZBL6, {variant: {options: ["qbIp4Rl2g", "ID1Tv58HS"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerWwXrTZBL6, [{explicitInter: true, fonts: []}, ...EmbedFonts], {supportsExplicitInterCodegen: true})